import { ElMessage } from 'element-plus'

const JLTools = {
  install(app) {
    app.config.globalProperties.JLTools = JLTools
  },
  isNull: function (...objs) {
    let bol = false,
      fn = function (obj) {
        if (typeof obj === 'undefined') {
          return true
        } else if (obj == null) {
          return true
        } else if (typeof obj === 'string' && (obj.trim() === '' || obj === 'undefined' || obj === 'null')) {
          return true
        } else if (typeof obj === 'object' && Array.isArray(obj) && obj.length == 0) {
          return true
        } else if (typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length == 0) {
          return true
        }
        return false
      }
    bol = objs.some((obj) => {
      return fn(obj)
    })
    return bol
  },
  myTimeToLocal(inputTime) {
    if (!inputTime && typeof inputTime !== 'number') {
      return ''
    }
    let localTime = ''
    inputTime = new Date(inputTime).getTime()
    const offset = new Date().getTimezoneOffset()
    localTime = new Date(inputTime - offset * 60000).toISOString()
    localTime = localTime.substr(0, localTime.lastIndexOf('.'))
    localTime = localTime.replace('T', ' ')
    return localTime
  },
  //加法函数，用来得到精确的加法结果
  //说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果
  accAdd(arg1, arg2) {
    let r1, r2, m
    try {
      if (arg1.toString().indexOf('.') === -1) {
        r1 = 0
      } else {
        let arg1Str = arg1.toString().split('.')[1]
        r1 += arg1Str.length
      }
    } catch (e) {
      console.error(e)
    }
    try {
      if (arg2.toString().indexOf('.') === -1) {
        r2 = 0
      } else {
        let arg2Str = arg2.toString().split('.')[1]
        r2 += arg2Str.length
      }
    } catch (e) {
      console.error(e)
    }
    m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
  },
  //减法函数，用来得到精确的减法结果
  //说明：javascript的减法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的减法结果
  accSub(arg1, arg2) {
    let r1, r2, m, n
    try {
      if (arg1.toString().indexOf('.') === -1) {
        r1 = 0
      } else {
        let arg1Str = arg1.toString().split('.')[1]
        r1 += arg1Str.length
      }
    } catch (e) {
      console.error(e)
    }
    try {
      if (arg2.toString().indexOf('.') === -1) {
        r2 = 0
      } else {
        let arg2Str = arg2.toString().split('.')[1]
        r2 += arg2Str.length
      }
    } catch (e) {
      console.error(e)
    }
    m = Math.pow(10, Math.max(r1, r2))
    //last modify by deeka
    //动态控制精度长度
    n = r1 >= r2 ? r1 : r2
    return ((arg1 * m - arg2 * m) / m).toFixed(n)
  },
  //乘法函数，用来得到精确的乘法结果
  //说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
  //调用：accMul(arg1,arg2)
  //返回值：arg1乘以 arg2的精确结果
  accMul(arg1, arg2) {
    let m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString()
    try {
      if (s1.toString().indexOf('.') === -1) {
        m = 0
      } else {
        let s1Str = s1.split('.')[1]
        m += s1Str.length
      }
    } catch (e) {
      console.error(e)
    }
    try {
      if (s2.toString().indexOf('.') === -1) {
        m = 0
      } else {
        let s2Str = s2.split('.')[1]
        m += s2Str.length
      }
    } catch (e) {
      console.error(e)
    }
    return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m)
  },
  //sku数据处理
  skuItem(specs) {
    if (!JLTools.isNull(specs)) {
      let firstAttrName = specs[0].firstAttrName //第一属性名称
      let firstAttrList = [{ name: '100886' }] //第一属性集合
      let secondAttrName = specs[0].secondAttrName //第二属性名称
      let secondAttrList = [{ name: '100886' }] //第二属性集合
      let thirdAttrName = specs[0].thirdAttrName //第三属性名称
      let thirdAttrList = [{ name: '100886' }] //第三属性集合
      specs.forEach((spec) => {
        //循环第一属性集合，specs没有相同的就添加进去
        let flag1 = true
        firstAttrList.forEach((res) => {
          if (!JLTools.isNull(spec.firstAttrValue) && spec.firstAttrValue == res.name) {
            flag1 = false
          }
        })
        if (flag1 && !JLTools.isNull(spec.firstAttrValue)) {
          firstAttrList.push({ name: spec.firstAttrValue, disable: false, img: spec.coverImg || '' })
        }
        //循环第二属性集合，specs没有相同的就添加进去
        let flag2 = true
        secondAttrList.forEach((res) => {
          if (!JLTools.isNull(spec.secondAttrValue) && spec.secondAttrValue == res.name) {
            flag2 = false
          }
        })
        if (flag2 && !JLTools.isNull(spec.secondAttrValue)) {
          secondAttrList.push({ name: spec.secondAttrValue, disable: false })
        }
        //循环第三属性集合，specs没有相同的就添加进去
        let flag3 = true
        thirdAttrList.forEach((res) => {
          if (!JLTools.isNull(spec.thirdAttrValue) && spec.thirdAttrValue == res.name) {
            flag3 = false
          }
        })
        if (flag3 && !JLTools.isNull(spec.thirdAttrValue)) {
          thirdAttrList.push({ name: spec.thirdAttrValue, disable: false })
        }
      })
      firstAttrList.splice(0, 1)
      secondAttrList.splice(0, 1)
      thirdAttrList.splice(0, 1)
      let itemData = {
        firstAttrName: firstAttrName,
        firstAttrList: firstAttrList,
        secondAttrName: secondAttrName,
        secondAttrList: secondAttrList,
        thirdAttrName: thirdAttrName,
        thirdAttrList: thirdAttrList
      }

      return itemData
    }
  },
  formatDate(d, format = 'YYYY-MM-DD') {
    let date
    if (typeof d === 'string') {
      date = new Date(d)
    } else {
      date = d
    }
    let str = ''
    let FullYear = date.getFullYear()
    let Month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    let Day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    let Hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    let Minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    let Seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    format = format.toUpperCase()
    format = format.replace('YYYY', FullYear)
    format = format.replace('MM', Month)
    format = format.replace('DD', Day)
    return format
  },
  //数字转金额格式
  forPrice(value) {
    //// console.log(value)
    if (!value) return 'Rs.0'
    var intPart = Number(value) | 0 //获取整数部分
    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
    return 'Rs. ' + intPartFormat
  },
  /**
   * 金额格式化
   * @param nStr
   * @returns {*}
   */
  format_number(nStr) {
    nStr += ''
    let x = nStr.split('.')
    let x1 = x[0]
    let x2 = x.length > 1 ? '.' + x[1] : ''
    let rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2')
    }
    return 'Rs. ' + x1 + x2
  },
  /**
   * 小数转为分数
   * @param point
   * @returns {string}
   */
  toPercent(point) {
    let percent = Number(point * 100)
    percent += '%'
    return percent
  },
  formatDateTime(d, format = 'YYYY-MM-DD HH:MI:SS') {
    if (this.isNull(d)) {
      d = new Date()
    }
    if (typeof d === 'string') {
      d = new Date(d)
    }
    if (d instanceof Date) {
      var FullYear = d.getFullYear()
      var Month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
      var Day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
      var Hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
      var Minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
      var Seconds = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()
      format = format.toUpperCase()
      format = format.replace('YYYY', FullYear)
      format = format.replace('MM', Month)
      format = format.replace('DD', Day)
      format = format.replace('HH', Hours)
      format = format.replace('MI', Minutes)
      format = format.replace('SS', Seconds)

      return format
    }
    return ''
  },
  formatDateLocaleString(isoDate) {
    const date = new Date(isoDate)

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false // 24小时制
    }

    // 格式化日期为 "Jul 15, 2024 17:30:24"
    return date.toLocaleString('en-US', options).replace(',', '')
  },

  toTop(x = 0, y = 0) {
    window.scrollTo(x, y)
  },
  scrollTo(x = 0) {
    window.scrollTo({
      top: x,
      behavior: 'smooth' // Smooth scrolling
    })
  },
  createCode(len) {
    let code = ''
    //设置长度，这里看需求，我这里设置了4
    let codeLength = len
    //设置随机字符
    let random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9)
    //循环codeLength 我设置的4就是循环4次
    for (let i = 0; i < codeLength; i++) {
      //设置随机数范围,这设置为0 ~ 36
      let index = Math.floor(Math.random() * 9)
      //字符串拼接 将每次随机的字符 进行拼接
      code += random[index]
    }

    return code
  },
  dateAdd(interval, number, date) {
    /*
     *   功能:实现JSScript的DateAdd功能.
     *   参数:interval,字符串表达式，表示要添加的时间间隔.
     *   参数:number,数值表达式，表示要添加的时间间隔的个数.
     *   参数:date,时间对象.
     *   返回:新的时间对象.
     *   var   now   =   new   Date();
     *   var   newDate   =   DateAdd( "d ",5,now);
     *---------------   DateAdd(interval,number,date)   -----------------
     */
    let nowDate
    switch (interval) {
      case 'y ':
        date.setFullYear(date.getFullYear() + number)
        nowDate = date
        break
      case 'q ':
        date.setMonth(date.getMonth() + number * 3)
        nowDate = date
        break
      case 'M ':
        date.setMonth(date.getMonth() + number)
        // // console.log(date)
        nowDate = date
        break
      case 'w ':
        date.setDate(date.getDate() + number * 7)
        nowDate = date
        break
      case 'd ':
        date.setDate(date.getDate() + number)
        nowDate = date
        break
      case 'h ':
        date.setHours(date.getHours() + number)
        nowDate = date
        break
      case 'm ':
        date.setMinutes(date.getMinutes() + number)
        nowDate = date
        break
      case 's ':
        date.setSeconds(date.getSeconds() + number)
        nowDate = date
        break
      default:
        date.setDate(d.getDate() + number)
        nowDate = date
        break
    }
    return nowDate
  },

  // 压缩图片
  formatUrl(url, quality) {
    quality = quality || 75
    let value = url || ''
    if (this.isNull(value)) return url
    if (typeof value === 'string' && value.indexOf('data:image/png') !== 0) {
      value = value.split(',')[0]
    } else if (Array.isArray(value)) {
      value = value[0]
    }
    if (value) {
      if (String(value).indexOf('http') == -1) {
        value = `${process.env.VUE_APP_IMG_URL}${value}?imageView2/0/format/webp/q/${quality}`
      } else {
        value = `${value}?imageView2/0/format/webp/q/${quality}`
      }
    }
    return value
  },

  // 复制文本
  copyText(text, toastText) {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    // 可以添加一些提示，告诉用户文本已经复制成功
    console.log('复制成功')
    if (toastText) {
      ElMessage.success(`${toastText}`)
    }
  }
}
export default JLTools
